@import "../GlobalVariable.module.css";

.InputModal {
  position: absolute;
  z-index: var(--modal-window-z-index);
  background-color: var(--error-background);
  box-shadow: 0 2px 8px rgba(var(--backdrop-color), 0.26);
  top: 10%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  border-radius: 7px;
  width: 440px;
  left: calc(50% - 220px);
}
.Child {
  font-size: 1rem;
}

.InputModal__actions {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  flex-direction: row-reverse;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  padding: 0 0.5rem;
}

@media (max-width: 550px) {
  .InputModal {
    left: 10%;
    width: 80%;
  }
  .InputModal h2 {
    padding: 0.8rem;
    font-size: 1rem;
  }
  .Child {
    font-size: 0.8rem;
  }
}

@media (max-width: 370px) {
  .InputModal h2 {
    padding: 0.5rem;
    font-size: 0.8rem;
  }
  .Child {
    font-size: 0.6rem;
  }
}
