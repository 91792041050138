.Container {
  margin: auto;
  width: 90%;
}

.Title {
  margin: 20px auto;
  text-align: center;
  font-size: 32px;
  padding: 10px;
}
