body {
  margin: 0;
  font-family: "Karla", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Karla";
  src: url("./dermusbase/assets/fonts/Karla-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Karla";
  src: url("./dermusbase/assets/fonts/Karla-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "Metropolis";
  src: url("./dermusbase/assets/fonts/Metropolis-ExtraBold.ttf")
    format("truetype");
}

a {
  color: var(--dermus_red);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

::selection {
  background-color: var(--dermus-blue-light);
  color: var(--default-color);
}

* {
  scrollbar-width: thin;
  scrollbar-color: #aad9f1 #f9f8fa;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f9f8fa;
}

*::-webkit-scrollbar-thumb {
  background-color: #aad9f1;
  border-radius: 20px;
}
