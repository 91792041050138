.Row {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
}
