@import "../GlobalVariable.module.css";
.Container {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  align-items: center;
  -webkit-align-items: center;
  padding: 10px;
}

.Spinner {
  color: var(--spinner-color);
  font-size: 4em;
}

.XSmall {
  font-size: 1em;
}

.Small {
  font-size: 2em;
}

.Medium {
  font-size: 3em;
}

.Large {
  font-size: 4em;
}