* {
  -webkit-tap-highlight-color: transparent;
}

:root {
  /*Primary colors*/
  --sidebar-toolbar-background-color: #f9f8fa;
  --sidebar-toolbar-text-color: #2b2b2b;
  --drawer-toggle-color: #00b3f4;
  --spinner-color: #86bfdb;
  --error-color: #e2304c;
  --error-background: white;
  --error-title-button-background: #aad9f1;
  --error-title-button-color: #2b2b2b;
  --shadow: transparent;
  --default-color: #2b2b2b;
  /*Toolbar, sidebar, menu*/
  --toolbar-height: 56px;
  --footer-height: 50px;
  /**Z-index*/
  --toolbar-z-index: 90;
  --sidebar-z-index: 150;
  --sidebar-backdrop-z-index: 100;
  --modal-backdrop-z-index: 200;
  --modal-window-z-index: 250;
  /*Backdrop*/
  --backdrop-color: 0, 0, 0;
  /*Input*/
  --element-focus-background: #f4f2f5;
  --invalid-border: #e2304c;
  --invalid-background: #fda49a;
  --background: #f4f2f5;
  --submit-button-backgrouns: #e2304c;
  --submit-button-disabled-color: #ccc;
  --submit-button-color: white;
  /*FloatingButton*/
  --fb-hint-bck: 51, 51, 51;
  --fb-hint-color: white;
  --fb-color: white;
  --floating-button: #86bfdb;
  --floating-shadow: transparent;
  --floating-button-hover: #e2304c;
  /***/
  --border: #e2304c;
  --box-backgorund-hover: #e3ecf3;
  /*ICD**/
  --border-icd: #ccc;
  --link-icd: #4169e1;
  --icd-details-backgorund-hover: #aad9f1;
  --icd-selected-backgorund: #aad9f1;
  --icd-selected-backgorund-hover: #aad9f1;
  --icd-found: #93d35f;
  --icd-found-noiden-part: #c5d43f;
  --dermus_red: #e2304c;
  --dermus_error_red: #e2304c;
  --dermus_warning_red: #e2304c;
  --dermus-blue-light: #aad9f1;
  --dermus-blue: #00b3f4;
}

/*
@media screen and (max-width: 500px) {
  :root {
    display: none;
  }
}
*/
