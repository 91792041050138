.AllData {
  padding: 20px;
  display: inline-flex;
  align-items: center;
  text-align: center;
}

.Select {
  padding: 10px;
}
