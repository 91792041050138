.Container {
  margin: auto;
  width: 80%;
}

@media (min-width: 1000px) {
  .Container {
    width: 800px;
  }
}
.Title {
  margin: 20px auto;
  text-align: center;
  font-size: 32px;
  padding: 10px;
}
