.Container {
  background-color: white;
  margin: 20px auto;
  width: 80%;
  text-align: center;
  box-sizing: content-box;
  width: 600px;
}

@media (max-width: 500px) {
  .Container {
    width: 400px;
  }
}

.Title {
  font-family: "Metropolis";
  font-size: 2.5em;
  text-align: left;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 20px;
  color: #2b2b2b;
}
