.Links {
  font-size: 0.7rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  height: 40px;
  letter-spacing: 0.05em;
  font-weight: bold;
}

.Background {
  background-image: url(/src/dermusbase/assets/images/dermus-texture.jpg);
  height: calc(100vh - var(--toolbar-height));
  overflow: hidden;
}

.Background_With_Footer {
  background-image: url(/src/dermusbase/assets/images/dermus-texture.jpg);
  height: calc(calc(100vh - var(--toolbar-height)) - var(--footer-height));
  overflow: hidden;
}

.LoginContainer {
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  height: 450px;
}

.LoginImg {
  flex: 1;
  margin: auto;
}

.LoginImg img {
  width: 100%;
  object-fit: fill;
}

.LoginForm {
  flex: 1;
}

input:-internal-autofill-selected {
  background-color: var(--dermus-blue-light);
}

@media (min-width: 500px) {
  .Links {
    font-size: 0.9rem;
    height: 50px;
  }
}

.Title {
  font-family: "Metropolis";
  font-size: 2.5em;
  text-align: left;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 20px;
  color: #2b2b2b;
}
