@import "../GlobalVariable.module.css";
.Input {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.InputElement {
  outline: none;
  border: 0px solid var(--border);
  background-color: var(--background);
  font: inherit;
  padding: 16px 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
  border-radius: 10px;
}

.InputElement:focus {
  outline: none;
  background-color: var(--element-focus-background);
}

.Invalid {
  border: 1px solid var(--invalid-border);
  background-color: var(--invalid-background);
}

.RadioElements {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 10px;
  align-content: center;
  flex-wrap: wrap;

  justify-content: space-around;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
}
.RadioElement {
  padding: 10px;
}

/* Customize the label (the container) */

.Label {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding-left: 30px;
}

/* Hide the browser's default checkbox */

.CheckBox {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.CheckmarkDisabled {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid var(--border);
  cursor: not-allowed;
  background-color: whitesmoke;
  border-radius: 30%;
}

/* Create a custom checkbox */

.Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid var(--border);
  border-radius: 30%;
}

.InvalidChecked {
  border: 1px solid var(--invalid-border);
  background-color: var(--invalid-background);
  border-radius: 30%;
}

/* On mouse-over, add a grey background color */

.Checkmark:hover {
  background-color: var(--box-backgorund-hover);
  border: 1px solid var(--box-backgorund-hover);
  cursor: pointer;
}

/* When the checkbox is checked, add a blue background */

.CheckBox:checked ~ .Checkmark {
  background-color: var(--submit-button-backgrouns);
}

/* Create the checkmark/indicator (hidden when not checked) */

.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.CheckBox:checked ~ .Checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.Checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 550px) {
  .InputElement {
    font-size: 0.8rem;
  }
  .Label {
    font-size: 0.8rem;
  }
  .Checkmark {
    height: 15px;
    width: 15px;
  }
  .Checkmark:after {
    left: 6px;
    top: 3px;
    width: 2px;
    height: 6px;
    border-width: 0 2px 2px 0;
  }
  .CheckmarkDisabled {
    height: 15px;
    width: 15px;
  }
}

@media (max-width: 370px) {
  .InputElement {
    font-size: 0.6rem;
  }
  .Label {
    font-size: 0.6rem;
  }
}
