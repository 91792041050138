@import "../GlobalVariable.module.css";

.Logo {
  background-color: var(--toolbar-height);
  height: 100%;
  box-sizing: border-box;
}

.Logo img {
  height: 100%;
}
