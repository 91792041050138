@import "../GlobalVariable.module.css";

.Icon {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: center;
}

.Icon svg {
  width: 40px;
  height: 40px;
}

.Icon a {
  color: var(--sidebar-toolbar-text-color);
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.Icon a:hover,
.Icon a:active,
.Icon a.active {
  color: var(--sidebar-toolbar-text-color);
}

@media (min-width: 500px) {
  .Icon {
    margin: 0;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    height: 100%;
    width: auto;
    -webkit-align-items: center;
    align-items: center;
  }

  .Icon a {
    height: 100%;
    padding: 4px 10px;
    border-bottom: 4px solid transparent;
  }

  .Icon a:hover,
  .Icon a:active,
  .Icon a.active {
    border-bottom: 4px solid var(--border);
  }
}

@media (max-width: 499px) {
  .Icon a:hover,
  .Icon a:active,
  .Icon a.active {
    text-decoration: underline;
  }
}
