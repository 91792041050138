.Children {
  font-size: 1rem;
  padding: 10px;
}

@media (max-width: 550px) {
  .Children {
    font-size: 0.8rem;
  }
}

@media (max-width: 370px) {
  .Children {
    font-size: 0.6rem;
  }
}
