@import "../../GlobalVariable.module.css";

.Toolbar {
  height: var(--toolbar-height);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--sidebar-toolbar-background-color);
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: var(--toolbar-z-index);
  box-shadow: 0px 2px 4px 2px var(--shadow);
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.Toolbar nav {
  height: 100%;
}

.Logo {
  height: 80%;
}
